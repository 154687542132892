import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    FormGroup,
    Input,
    Label,
    Table,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";

import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

import FormRow from "../../components/Row/FormRow"
import { AvForm } from 'availity-reactstrap-validation';
import strings from "../../core/translate";
import SelectRow from "../../components/Select/SelectRow";


export default class UserMissionAddUpdate extends CoreEngine {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            _id: "",
            title: "",
            fullpicture:"",
            status:"pending",
            username:"",
            coin:"0"
        };
        this.pathname = "missionuser";
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        const edit = this.props.location.data
        debugger
        if (edit) {
            debugger
            this.setState({
                ...edit,
                title:edit?.mission?.title,
                coin:edit?.mission?.coin || "0",
                username:edit?.user?.username
            });
        }
    }
    handleValidSubmit = async () => {

        this.setState({
            loading: true
        })

        let data = { ...this.state }

        const response = await this.engine.saveItem(this.pathname, data)

        if (response && response.status === 200) {
            this.props.history.push('/admin/' + this.pathname);
        }
        this.setState({
            loading: false
        })

    };


    render() {
        // taking all the states
        const { loading, isLoading,title,username,  fullpicture,status,
            coin} = this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <div className="content english">

                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm onValidSubmit={() => this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">{strings.information}</CardTitle>
                                    </CardHeader>
                                    <CardBody>

                                        <img src={fullpicture} width={200}/>
                                        <FormRow label={"coin"} readonly={true} name="coin" data={coin.toString()} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label={"title"} readonly={true} name="title" data={title.toString()} changeInput={this.changeInput.bind(this)} />

                                        <FormRow label={"username"} readonly={true} name="username" data={username.toString()} changeInput={this.changeInput.bind(this)} />

                                        <SelectRow
                                            label={"status"}
                                            name="status"
                                            defaultValue={status}
                                            data={this.createSelectValue(["accepted","pending"])}
                                            changeInput={this.changeInput.bind(this)}
                                        />

                                    </CardBody>

                                    <CardFooter className="text-center">
                                        <ButtonLoader color="primary" loading={loading}>{strings.save}</ButtonLoader>
                                    </CardFooter>
                                </Card>
                            </AvForm>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


